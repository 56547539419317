import React from 'react';

import { useState } from 'react';

import { Link } from 'gatsby'

import "../styles/menu.css";


import Mail from '../components/mail'

function MenuMobil({ section }) {
    // Defini la section active du menu
     const sectionActive = section   

     // State for Menu Mibil open and close
    const [menuOpen, setMenuOpen] = useState(false);
    
    function openMenu(e) {
        setMenuOpen(true)
    }
    function closeMenu(e) {
        setMenuOpen(false)
    }

    return (
        <div className={menuOpen ? "menuMobilOpen" : "menuMobil"}>
            
            <nav>
                <span className={menuOpen ? "hide" : "logoMenuMobil"}><Link className={sectionActive === "home" ? "hide" : ""} to='/'>Mayte Valdez</Link></span>
          
                <button onClick={(e)=>openMenu(e)} className={menuOpen ? "hide" : "btnMenu"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                    </svg> 
                </button>
                <button onClick={(e)=>closeMenu(e)} className={menuOpen ? "btnMenu closeBtn" : "hide"} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <ul className={menuOpen ? ""  : "hide"}>
                    <li><Link  onClick={(e)=>closeMenu(e)}  to='/'><span className="tanFont logoMenuOpen">Mayte<br/> Valdez</span></Link></li>
                    <li><Link  onClick={(e)=>closeMenu(e)}  to='/about'>About</Link></li>
                    <li><Link  onClick={(e)=>closeMenu(e)}  to='/work'>Work</Link></li>
                    <li><Link  onClick={(e)=>closeMenu(e)}  to='/contact'>Contact</Link></li>
                    <li><Link  onClick={(e)=>closeMenu(e)}  to='/playground'>PlayGround</Link></li>
                    <li className="mailMenu"> 
                        <Mail/>
                    <a href="mailto:may.zedlav@gmail.com">may.zedlav@gmail.com</a>
                    </li>
                </ul>
                <div className={menuOpen ? "flexH rsMenu"  : "hide"} >
                    <a className='link' href="https://www.linkedin.com/in/valdezmayte/" rel="noreferrer" target="_blank">Linkedin</a>
                    <p>|</p>
                    <a className='link' href="https://dribbble.com/may_vdz" rel="noreferrer" target="_blank">Dribble</a> 
                    <p>|</p>
                    <a className="link" href="https://www.behance.net/may-valdez" rel="noreferrer" target="_blank">Behance</a>
                </div>
            </nav> 
             
             <ul> 
                
                
             </ul>
             
        </div>
        
    )
       
}

export default MenuMobil;