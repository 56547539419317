import * as React from 'react'
import {
  container
} from './layout.module.css'
import Menu from './menu'

import Clouds from "../components/clouds";

import SayHi from '../components/sayHi'
import MenuMobil from './menuMobil'
import Footer from './footer';
import Seo from './seo';

const Layout = ({ pageTitle, children }) => {
  return (
    <div>
      <main className={container}>
      <Menu section={pageTitle} />
      <MenuMobil section={pageTitle} />
      <Seo/>

      <Clouds/>
      {children}

      <SayHi/>
      
    </main>
    <Footer section={pageTitle}/>
    </div>
    
  )
}

export default Layout