import React from "react";


import {
    mailAnim
  } from './mail.module.css'



function Mail({styleMail}) {
 

  return (
    <svg
      className={styleMail ? mailAnim : ""}  
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      fill="none"
      viewBox="0 0 51 51"
    >
      <path
        fill="#F8CDE2"
        d="M5.37 13.108c-.23.48-.37 1.012-.37 1.58v22.125c0 .567.14 1.099.37 1.58L18.094 25.75 5.37 13.108zM43.269 11H8.688c-.568 0-1.1.14-1.58.37L23.37 27.55a3.69 3.69 0 005.215 0l16.263-16.18a3.645 3.645 0 00-1.58-.37zM46.587 13.108L33.862 25.75l12.725 12.643c.23-.481.37-1.013.37-1.58V14.687c0-.567-.14-1.099-.37-1.58z"
      ></path>
      <path
        fill="#F8CDE2"
        d="M32.124 27.488l-1.8 1.8a6.152 6.152 0 01-8.691 0l-1.8-1.8L7.107 40.131c.48.23 1.012.37 1.58.37h34.58c.569 0 1.1-.14 1.58-.37L32.125 27.488z"
      ></path>
    </svg>
  );
}

export default Mail;