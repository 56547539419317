import React from 'react';

import { Link } from 'gatsby'

import "../styles/menu.css";


function Menu({ section }) {
    // Defini la section active du menu
     const sectionActive = section   

    return (
        <div className={sectionActive === "home" ? "menu menuHome" : "menu"}>
            
            
            <nav>
                <span className="logoMenu"><Link className={sectionActive === "home" ? "hide" : ""} to='/'>Mayte Valdez</Link></span>
                <ul >
                    <li><Link className={sectionActive === "about" ? "selectSection" : ""} to='/about'>About</Link></li>
                    <span className="separator"></span>
                    <li><Link className={sectionActive === "work" ? "selectSection" : ""} to='/work'>Work</Link></li>
                    <span className="separator"></span>
                    <li><Link className={sectionActive === "contact" ? "selectSection" : ""} to='/contact'>Contact</Link></li>
                    <span className="separator"></span>
                    <li><Link className={sectionActive === "playground" ? "selectSection" : ""} to='/playground'>PlayGround</Link></li>
                </ul>
            </nav> 
             
             <ul> 
                
                
             </ul>
             
        </div>
        
    )
       
}

export default Menu;