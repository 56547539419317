import React from "react";
import {
  clouds, c1, c2, c3
} from './clouds.module.css'

function Clouds () {
  return (
    <>
      <svg
        className={clouds}
        id={c1}
        xmlns="http://www.w3.org/2000/svg"
        width="213"
        height="50"
        fill="none"
        viewBox="0 0 213 50"
        >
        <path
              fill="#fff"
              d="M205.192 49.56c5.631 0 8.978-6.34 5.786-10.986-7.392-10.78-20.396-17.924-35.212-17.924a44.551 44.551 0 00-15.896 2.912C153.002 9.508 139.875 0 124.802 0 110.44 0 97.838 8.642 90.744 21.62c-9.843-5.203-21.23-8.198-33.379-8.198-23.629 0-44.416 11.265-56.451 28.301-2.317 3.283.04 7.837 4.056 7.837h200.222z"
          ></path>
      </svg>
      <svg
      className={clouds}
      id={c2}
      xmlns="http://www.w3.org/2000/svg"
      width="167"
      height="55"
      fill="none"
      viewBox="0 0 167 55"
    >
      <path
        fill="#fff"
        d="M156.748 54.879c12.66 0 13.312-19.061.667-19.635-.232-.01-.469-.015-.707-.015-2.693 0-5.247.697-7.555 1.948-.84.455-1.878-.024-2.051-.964C143.268 15.496 127.909 0 109.537 0 90.329 0 74.418 16.93 71.522 39.066c-.163 1.246-1.774 1.647-2.51.623-7.392-10.255-18.911-16.855-31.858-16.855C20.803 22.834 6.72 33.35.365 48.47c-1.285 3.04.983 6.408 4.284 6.408h152.099z"
      ></path>
      </svg>


      <svg
        className={clouds}
        id={c3}
        xmlns="http://www.w3.org/2000/svg"
        width="167"
        height="55"
        fill="none"
        viewBox="0 0 167 55"
      >
        <path
          fill="#fff"
          d="M156.748 54.879c12.66 0 13.312-19.061.667-19.635-.232-.01-.469-.015-.707-.015-2.693 0-5.247.697-7.555 1.948-.84.455-1.878-.024-2.051-.964C143.268 15.496 127.909 0 109.537 0 90.329 0 74.418 16.93 71.522 39.066c-.163 1.246-1.774 1.647-2.51.623-7.392-10.255-18.911-16.855-31.858-16.855C20.803 22.834 6.72 33.35.365 48.47c-1.285 3.04.983 6.408 4.284 6.408h152.099z"
        ></path>
      </svg>

    </>
   
  );
}

export default Clouds;