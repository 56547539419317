import React from 'react';

//import { Link } from 'gatsby'

import {
    footer, footerContent, footerBkg, footerLogo
  } from './footer.module.css'


function Footer({ section }) {
    // Defini la section active du menu
     const sectionActive = section   

    return (
        <footer className={sectionActive === "home" ?  footer : footerBkg }>
            <div className={footerContent}>
               <p><small>&copy; {new Date().getFullYear()}. All rights reserved.</small></p>
                <p><small>Website designed and coded by <span className={footerLogo}>Mayte Valdez</span>.</small> </p> 
            </div>
            
        </footer>
        
    )
       
}

export default Footer;


